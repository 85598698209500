import { navigate } from "gatsby";
import React from "react"
import {setUser} from "../services/auth"

class Login extends React.Component {

  constructor() {
		super();
		this.state = {
			message: ""
		}
	}
   

	render() {
    setUser({})
		if(typeof window !== 'undefined'){
			navigate('/')
		}
		return(<></>)
	}
}

export default Login;